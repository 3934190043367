'use client'

import { Fragment, ReactComponentElement, ReactNode } from 'react'
import { Spacer, Divider } from '@vinted/web-ui'
import { compact } from 'lodash'

import { BreakpointMap, useBreakpoint } from '@marketplace-web/shared/breakpoints'

import { isEmptyFragment } from './utils'

type Props = {
  children: Array<ReactNode>
  separator?: ReactComponentElement<typeof Divider | typeof Spacer>
  separatorAtBreakpoint?: Partial<
    Record<keyof BreakpointMap, ReactComponentElement<typeof Divider | typeof Spacer>>
  >
}

const SeparatedList = ({
  children,
  separator = <Spacer orientation={Spacer.Orientation.Vertical} />,
  separatorAtBreakpoint,
}: Props) => {
  const breakpoints = useBreakpoint()

  function getBreakpointSeparator() {
    if (!separatorAtBreakpoint) return separator

    const activeBreakpoint = breakpoints.active.find(
      breakpoint => separatorAtBreakpoint[breakpoint],
    )

    if (!activeBreakpoint) return separator

    return separatorAtBreakpoint[activeBreakpoint]
  }

  const items = compact(children.flat()).filter(child => !isEmptyFragment(child))

  if (!items?.length) {
    return null
  }

  const breakpointSeparator = getBreakpointSeparator()

  return items.map((item, index) => (
    <Fragment key={index}>
      {item}
      {items.length - 1 !== index && breakpointSeparator}
    </Fragment>
  ))
}

export default SeparatedList
