import { useMemo } from 'react'

import { useSession } from '@marketplace-web/shared/session'
import { useSystemConfiguration } from '@marketplace-web/shared/system-configuration'

import { Exposee } from '../types'

const useExposee = () => {
  const session = useSession()
  const systemConfiguration = useSystemConfiguration()

  return useMemo(
    (): Exposee => ({
      anonId: session.anonId || '',
      userId: session.user?.id || null,
      countryCode: systemConfiguration?.userCountry || '',
    }),
    [session.anonId, session.user?.id, systemConfiguration?.userCountry],
  )
}

export default useExposee
