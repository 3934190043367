import { ReactNode } from 'react'
import { isFragment } from 'react-is'
import { compact, isArray, isEmpty } from 'lodash'

import { isObject } from '@marketplace-web/shared/utils'

export const isEmptyFragment = (instance: ReactNode) => {
  if (!isFragment(instance)) return false
  if (!isObject(instance.props)) return true

  const children = instance.props?.children

  if (isArray(children)) return isEmpty(compact(children))

  return !children
}
