export enum AccessChannel {
  VintedGuide = 'vinted_guide',
  HcSearch = 'hc_search',
  HcTransaction = 'hc_transaction',
  HcTopics = 'hc_topics',
  ConversationTx = 'conversation_tx',
  ConversationNoTx = 'conversation_no',
  ProductLink = 'product_link',
  ExternalLink = 'external_link',
  DataExport = 'data_export',
}
