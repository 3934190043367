import { useContext } from 'react'

import { FeatureSwitchesContext } from '../contexts/FeatureSwitchesProvider'
import { isFeatureEnabled } from '../utils'

const useFeatureSwitch = (feature: string): boolean => {
  const featureSwitches = useContext(FeatureSwitchesContext)

  if (!featureSwitches) throw new Error('featureSwitches context is not set')

  return isFeatureEnabled(feature, featureSwitches)
}

export default useFeatureSwitch
