'use client'

import { useContext } from 'react'

import { AddressDto } from 'types/dtos/address'
import { UserFundraiserDto } from '@marketplace-web/shared/user-data'
import { UserBundleDiscountDto } from 'types/dtos/bundle-discount'

import { SessionStateContext, SessionDispatchContext } from '../contexts/SessionProvider'

const useSession = () => {
  const sessionState = useContext(SessionStateContext)
  const sessionDispatch = useContext(SessionDispatchContext)

  if (!sessionState || !sessionDispatch) {
    throw new Error('useSession must be used within a SessionProvider')
  }

  return {
    ...sessionState,
    setDefaultAddress: (defaultAddress: AddressDto | null) =>
      sessionDispatch({ type: 'SET_DEFAULT_ADDRESS', payload: defaultAddress }),
    setFundraiserConfiguration: (configuration: UserFundraiserDto | null) =>
      sessionDispatch({ type: 'SET_FUNDRAISER_CONFIGURATION', payload: configuration }),
    setHasConfirmedPaymentsAccount: (hasConfirmed: boolean) =>
      sessionDispatch({ type: 'SET_HAS_CONFIRMED_PAYMENTS_ACCOUNT', payload: hasConfirmed }),
    setUserBundleDiscounts: (bundleDiscounts: UserBundleDiscountDto | null) =>
      sessionDispatch({ type: 'SET_USER_BUNDLE_DISCOUNTS', payload: bundleDiscounts }),
  }
}

export default useSession
