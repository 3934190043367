'use client'

import { Button, Cell, Checkbox, Divider, InputText, Note, Spacer, Text } from '@vinted/web-ui'
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'

import { getFingerprint } from '@marketplace-web/domain/audit'
import { useDataDomeCaptcha } from '@marketplace-web/domain/data-dome'
import { ResponseError } from '@marketplace-web/shared/api-client'
import { AuthenticateGrantType } from '@marketplace-web/shared/authentication'
import { navigateToPage } from '@marketplace-web/shared/browser'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { useSession } from '@marketplace-web/shared/session'
import {
  Notification,
  renderValidation,
  useFormValidationMessage,
} from '@marketplace-web/shared/ui-helpers'
import { toUrlQuery } from '@marketplace-web/shared/utils'

import { viewScreenEvent } from '_libs/common/event-tracker/events'
import FaqEntryUrl from 'components/FaqEntryUrl'
import { AccessChannel } from 'constants/access-channel'
import { FaqEntryType } from 'constants/faq-entry'
import { ROOT_URL } from 'constants/routes'
import { Screen } from 'constants/tracking/screens'
import {
  resendLoginTwoFactorCode,
  resendTwoFactorCode,
  resendTwoFactorVerifierCode,
  sendTwoFactorCode,
  verifyPhoneNumber,
} from 'data/api'
import { authenticateUser } from 'data/api/authentication/requests'
import { CodeLoginUserArgs } from 'types/api/arguments/code-login-user-args'
import { TwoFactorResendResp } from 'types/api/response/two-factor-resend-resp'

import { VerificationType } from './constants'

const RESEND_TIMER_INTERVAL = 1000

type Props = {
  twoFAId: number
  maskedNumber?: string
  redirectUrl?: string
  refUrl?: string
  verificationType: VerificationType
  controlCode?: string
  nextResendAvailableIn: number
  showResend: boolean
  maskedInfo?: string
  onCodeSent?: () => void
  isVerifierEnabled?: boolean
  faqEntryType?: FaqEntryType
  // TODO: remove when non_native_flow AB test is scaled
  isAuthPage?: boolean
}

type FormData = {
  code: string
}

const TwoFactorVerification = ({
  verificationType,
  controlCode,
  showResend,
  nextResendAvailableIn: defaultResendAvailableIn,
  maskedInfo,
  maskedNumber,
  twoFAId,
  onCodeSent,
  refUrl,
  redirectUrl,
  isVerifierEnabled,
  faqEntryType = FaqEntryType.SmsVerfication,
  isAuthPage,
}: Props) => {
  const translate = useTranslate('user.two_factor_verification')
  const { track } = useTracking()
  const userId = useSession().user?.id
  const {
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<FormData>()
  const [nextResendAvailableIn, setNextResendAvailableIn] = useState(defaultResendAvailableIn)
  const [isResendVisible, setIsResendVisible] = useState(showResend)
  const [isResendNotificationVisible, setIsResendNotificationVisible] = useState(false)
  const [isTrustedDevice, setIsTrustedDevice] = useState(true)
  const resendCounterTimeout = useRef<ReturnType<typeof setInterval> | null>(null)
  const getErrorMessage = useFormValidationMessage(errors)

  const screen =
    verificationType === VerificationType.Phone
      ? Screen.PhoneVerificationCheck
      : Screen.TwoFactorAuthentication

  useEffect(() => {
    track(viewScreenEvent({ screen }))
  }, [track, screen])

  const initResendCounter = useCallback(() => {
    resendCounterTimeout.current = setInterval(() => {
      setNextResendAvailableIn(prevState => prevState - 1)
    }, RESEND_TIMER_INTERVAL)
  }, [])

  useEffect(() => {
    if (!resendCounterTimeout.current) return

    if (nextResendAvailableIn <= 0) {
      clearInterval(resendCounterTimeout.current)
    }
  }, [nextResendAvailableIn])

  useEffect(() => {
    initResendCounter()

    return () => {
      if (!resendCounterTimeout.current) return

      clearInterval(resendCounterTimeout.current)
    }
  }, [initResendCounter])

  const onResendClick = async () => {
    try {
      let resendResponse: TwoFactorResendResp | ResponseError
      const code = getValues('code')

      switch (verificationType) {
        case VerificationType.Login:
          if (!controlCode) return

          if (isVerifierEnabled) {
            resendResponse = await resendTwoFactorVerifierCode(controlCode)

            return
          }

          resendResponse = await resendLoginTwoFactorCode({
            controlCode,
            code,
            fingerprint: await getFingerprint(),
          })
          break
        default:
          if (!userId) return

          resendResponse = await resendTwoFactorCode({
            userId,
            id: twoFAId,
            code,
            fingerprint: await getFingerprint(),
          })
      }

      if ('errors' in resendResponse) {
        throw Error(resendResponse.errors.toString() || 'Error while resending')
      }

      setNextResendAvailableIn(resendResponse.next_resend_available_in)
      setIsResendVisible(resendResponse.show_resend_option)
      initResendCounter()
    } catch (exception) {
      setIsResendVisible(false)
      setIsResendNotificationVisible(true)
    }
  }

  const handleCodeSentAction = () => {
    switch (verificationType) {
      case VerificationType.BankAccount:
        if (refUrl && redirectUrl) {
          navigateToPage(`${refUrl}?${toUrlQuery({ ref_url: redirectUrl })}`)
        }
        break
      case VerificationType.Checkout:
      case VerificationType.EntityHash:
        onCodeSent?.()
        break
      default:
        break
    }
  }

  const setVerificationError = (message: string) => {
    setError('code', { type: 'manual', message })
  }

  const sendCode = async ({ code }: FormData) => {
    try {
      switch (verificationType) {
        case VerificationType.Login: {
          if (!controlCode) return

          const authenticateUserParams: CodeLoginUserArgs = {
            grantType: AuthenticateGrantType.Password,
            controlCode,
            verificationCode: code,
            passwordType: 'two_factor_challenge_code',
            isTrustedDevice,
            fingerprint: await getFingerprint(),
          }

          const response = await authenticateUser(authenticateUserParams)

          // TODO: update error handling when refactoring
          if ('errors' in response) {
            setVerificationError(response.message || '')

            return
          }

          navigateToPage(refUrl || ROOT_URL)
          break
        }
        case VerificationType.BankAccount:
        case VerificationType.Checkout:
        case VerificationType.EntityHash: {
          if (!userId) return

          const sendTwoFactorCodeResponse = await sendTwoFactorCode({
            userId,
            id: twoFAId,
            code,
            fingerprint: await getFingerprint(),
          })

          if ('errors' in sendTwoFactorCodeResponse) {
            const error = sendTwoFactorCodeResponse.errors[0]!

            setVerificationError(error.value)

            return
          }

          handleCodeSentAction()
          break
        }
        case VerificationType.Phone: {
          if (!userId || !maskedNumber) return

          const verifyPhoneNumberResponse = await verifyPhoneNumber({
            userId,
            code,
            phoneNumber: maskedNumber,
            fingerprint: await getFingerprint(),
          })

          if ('errors' in verifyPhoneNumberResponse) {
            const error = verifyPhoneNumberResponse.errors[0]!

            setVerificationError(error.value)

            return
          }

          if (refUrl) navigateToPage(refUrl)
          break
        }
        default:
          break
      }
    } catch (error) {
      setVerificationError(
        error.response?.data.errors &&
          error.response.data.errors.length &&
          error.response.data.errors[0],
      )
    }
  }

  useDataDomeCaptcha(() => {
    handleSubmit(sendCode)()
  })

  const handleTrustedDeviceChange = () => {
    setIsTrustedDevice(prevIsTrusted => !prevIsTrusted)
  }

  const renderHeader = () => (
    <Cell
      body={
        <>
          <Text
            as="h1"
            text={translate('title')}
            type={Text.Type.Heading}
            alignment={isAuthPage ? Text.Alignment.Left : Text.Alignment.Center}
            width={Text.Width.Parent}
          />
          <Spacer size={Spacer.Size.Large} />
          <span className="u-text-wrap">
            {translate('body', {
              phone_number: (
                <Text
                  key="phone-number"
                  as="span"
                  text={maskedInfo || maskedNumber}
                  bold
                  theme="amplified"
                />
              ),
            })}
          </span>
        </>
      }
    />
  )

  const renderVerificationCodeInput = () => {
    return (
      <InputText
        {...register('code')}
        type={InputText.Type.Number}
        validation={renderValidation(getErrorMessage('code'))}
        placeholder={translate('placeholder')}
        testId="verification-code"
      />
    )
  }

  const renderSendButton = () => (
    <Cell>
      <Button
        styling={Button.Styling.Filled}
        text={translate('continue')}
        type={Button.Type.Submit}
        isLoading={isSubmitting}
        testId="verify-button"
        onClick={(event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
          if (isSubmitting) {
            event.preventDefault()
          }
        }}
      />
    </Cell>
  )

  const renderTrustedDeviceCheckbox = () => {
    if (verificationType !== VerificationType.Login) return null

    return (
      <>
        <Cell
          title={translate('trusted_device.title')}
          body={translate('trusted_device.body')}
          suffix={
            <Checkbox
              testId="is-trusted-device"
              name="isTrustedDevice"
              checked={isTrustedDevice}
              onChange={handleTrustedDeviceChange}
            />
          }
        />
        <Divider />
      </>
    )
  }

  const renderForm = () => (
    <form onSubmit={handleSubmit(sendCode)}>
      {renderVerificationCodeInput()}
      {renderTrustedDeviceCheckbox()}
      {renderSendButton()}
    </form>
  )

  const renderContactUsLink = () => (
    <FaqEntryUrl
      key="contact-us-link"
      type={faqEntryType}
      accessChannel={AccessChannel.ProductLink}
      render={url => (
        <a href={url} target="_blank" rel="noreferrer">
          {translate('contact_us')}
        </a>
      )}
    />
  )

  const renderNote = (text: ReactNode) => (
    <Note styling={Note.Styling.Narrow} alignment={Note.Alignment.Center} text={text} />
  )

  const renderContactUsNote = () =>
    renderNote(translate('note', { contact_us: renderContactUsLink() }))

  const renderResendButton = () => {
    if (!isResendVisible) return null

    const secondsleft = nextResendAvailableIn.toString().padStart(2, '0')

    return (
      <>
        {renderNote(
          nextResendAvailableIn > 0 ? (
            translate('resend_counter', { seconds_left: secondsleft })
          ) : (
            <Button
              styling={Button.Styling.Flat}
              size={Button.Size.Small}
              inline
              onClick={onResendClick}
              text={translate('resend_link')}
            />
          ),
        )}
      </>
    )
  }

  const renderNotification = () => {
    if (!isResendNotificationVisible) return null

    return <Notification body={translate('resend_error')} />
  }

  return (
    <>
      {renderHeader()}
      {renderForm()}
      {renderResendButton()}
      {renderContactUsNote()}
      {renderNotification()}
    </>
  )
}

export default TwoFactorVerification
