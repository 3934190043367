'use client'

import {
  forwardRef,
  Component,
  ChangeEvent,
  Ref,
  FocusEvent,
  KeyboardEvent,
  ReactNode,
  ClipboardEventHandler,
  AriaAttributes,
} from 'react'
import classNames from 'classnames'

import { idFromName } from '../../utils/html'

type Props = {
  name: string
  validation?: ReactNode
  title?: string | JSX.Element
  value?: string | number | null
  min?: string | number
  max?: string | number
  step?: string | number
  maxLength?: number
  icon?: ReactNode
  placeholder?: string
  readOnly?: boolean
  styling?: 'narrow' | 'wide' | 'tight'
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  onCopy?: ClipboardEventHandler<HTMLInputElement>
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
  forwardedRef?: Ref<HTMLInputElement>
  uncontrolled?: boolean
  autoComplete?: string
  aria?: AriaAttributes
  testId?: string
  inputMode?: 'decimal'
}

type DefaultProps = {
  required: boolean
  disabled: boolean
  type: 'text' | 'password' | 'number' | 'email' | 'date'
  ignoreState: boolean
}

type State = {
  inputValue: string | number
}

/** @deprecated Use @vinted/web-ui version of InputText instead or provide reasons why you must use this one */
class DeprecatedInputText extends Component<Props & DefaultProps, State> {
  static defaultProps = {
    required: false,
    disabled: false,
    type: 'text',
    ignoreState: false,
  }

  state = { inputValue: this.formatValue(this.props.value) }

  componentDidUpdate(prevProps: Props) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        inputValue: this.formatValue(this.props.value),
      })
    }
  }

  // TODO: convert into functional component
  // eslint-disable-next-line class-methods-use-this
  formatValue(value: string | number | null | undefined) {
    return value || ''
  }

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { onChange, uncontrolled } = this.props

    if (!uncontrolled) {
      const value = this.formatValue(event.target.value)

      this.setState({ inputValue: value })
    }

    if (onChange) onChange(event)
  }

  render() {
    const {
      name,
      validation,
      title,
      type,
      required,
      disabled,
      min,
      max,
      step,
      maxLength,
      icon,
      placeholder,
      readOnly,
      styling,
      onFocus,
      onBlur,
      onKeyDown,
      onCopy,
      forwardedRef,
      uncontrolled,
      autoComplete,
      aria,
      testId,
      inputMode,
    } = this.props

    const inputClass = classNames('c-input', {
      [`c-input--${String(styling)}`]: styling !== undefined,
    })

    const value = this.props.ignoreState
      ? this.formatValue(this.props.value)
      : this.state.inputValue

    return (
      <label htmlFor={name} className={inputClass}>
        {title && <div className="c-input__title">{title}</div>}
        <div className="c-input__content">
          <input
            className="c-input__value"
            type={type}
            name={name}
            id={idFromName(name)}
            value={uncontrolled ? undefined : value}
            required={required}
            disabled={disabled}
            min={min}
            max={max}
            step={step}
            maxLength={maxLength}
            placeholder={placeholder}
            readOnly={readOnly}
            onChange={this.handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            onCopy={onCopy}
            ref={forwardedRef}
            data-testid={testId}
            inputMode={inputMode}
            autoComplete={autoComplete}
            {...aria}
          />
          {icon && <div className="c-input__icon">{icon}</div>}
        </div>

        {validation && <div className="c-input__note">{validation}</div>}
      </label>
    )
  }
}

/** @deprecated Use @vinted/web-ui version of InputText instead or provide reasons why you must use this one */
const DeprecatedInputTextWithForwardedRef = forwardRef(
  (props: Props & Partial<DefaultProps>, ref?: Ref<HTMLInputElement>) => (
    <DeprecatedInputText forwardedRef={ref} {...props} />
  ),
)

export default DeprecatedInputTextWithForwardedRef
