'use client'

import { useEffect } from 'react'

const CAPTCHA_PASSED_EVENT_NAME = 'dd_response_passed'

const useDataDomeCaptcha = (callback: () => void) => {
  useEffect(() => {
    window.addEventListener(CAPTCHA_PASSED_EVENT_NAME, callback)

    return () => {
      window.removeEventListener(CAPTCHA_PASSED_EVENT_NAME, callback)
    }
  }, [callback])
}

export default useDataDomeCaptcha
