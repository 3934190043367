import { FeatureSwitches } from '../types'

export const isFeatureEnabled = (
  featureSwitchName: string,
  featureSwitches: FeatureSwitches,
): boolean => {
  const featureSwitch = featureSwitches[featureSwitchName]

  if (!featureSwitch) return false

  return featureSwitch.enabled
}
