export enum UiState {
  Idle = 'IDLE',
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Failure = 'FAILURE',
}

export enum ColorCode {
  Primary = 'primary-default',
  Success = 'success-default',
  Expose = 'expose-default',
  Warning = 'warning-default',
  PrimaryLight = 'primary-light',
  SuccessLight = 'success-light',
  ExposeLight = 'expose-light',
  WarningLight = 'warning-light',
  PrimaryMedium = 'primary-medium',
  SuccessMedium = 'success-medium',
  ExposeMedium = 'expose-medium',
  WarningMedium = 'warning-medium',
  GreyscaleLevel1 = 'greyscale-level-1',
  GreyscaleLevel2 = 'greyscale-level-2',
  GreyscaleLevel3 = 'greyscale-level-3',
  GreyscaleLevel4 = 'greyscale-level-4',
  GreyscaleLevel5 = 'greyscale-level-5',
  GreyscaleLevel6 = 'greyscale-level-6',
  GreyscaleLevel7 = 'greyscale-level-6',
  Facebook = 'facebook',
  Google = 'google',
  Apple = 'apple',
}
