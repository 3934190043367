export enum KeyboardKey {
  Escape = 'Escape',
  Tab = 'Tab',
  Enter = 'Enter',
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  Backspace = 'Backspace',
  Spacebar = ' ',
}
